import { useAlert } from 'react-alert';

const useSendAlert = () => {
  const alert = useAlert();

  const sendAlert = (message, type = 'success') => {
    const alertMessage = (
      <div
        style={{
          color: 'white',
          textTransform: 'capitalize',
          fontSize: '1.5rem'
        }}
      >
        <span className='float-left'>{message}</span>
      </div>
    );
    if (type === 'success') {
      alert.success(alertMessage);
    } else {
      alert.error(alertMessage);
    }
  };
  return sendAlert;
};

export { useSendAlert };

import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import {
  GET_WALLET_BALANCE,
  GET_TRANSACTION_REFERENCE,
  GET_USER,
  GET_WALLET,
  GET_TRANSACTIONS,
  GET_SHIPMENTS,
  GET_SHIPMENT_DETAILS,
  GET_SAVED_ADDRESSES,
  GET_SHIPMENT_RATES,
  TRACK_SHIPMENT,
  GET_BANK_LIST,
  GET_BANK_ACCOUNT_NAME,
  GET_BANK_ACCOUNTS,
  GET_WITHDRAWAL_LOGS,
  GET_COUNTRIES,
  GET_STATES,
  GET_CITIES
} from './definitions/queries.def';
import { useRecoilState } from 'recoil';
import {
  transactionReferenceState,
  walletState,
  saveShipmentRateState,
  shipmentRequestState,
  saveShipmentDetailsState,
  saveSenderAddressState,
  saveReceiverAddressState,
  allBankDetailsState,
  quotesLocationDataState,
  shippingRouteState
} from '../recoil/atoms';
import { useSaveAddressMutation } from './mutations';
import { parseGraphqlError } from '../utilities/parse-graphql-error';
import { useSendAlert } from '../utilities/send-alert';
import { useGetUserPayload } from '../utilities/get-payload';
import { transactionTypesEnum } from './../utilities/enums/transaction-types.enum';

const useGetWalletBalanceQuery = () => {
  return useQuery(GET_WALLET_BALANCE, {
    onCompleted: data => {
      localStorage.setItem('tempToken', null);
    },
    onError: error => {},
  });
};

const useChatGetShipmentRateQuery = (shipmentDetails) => {
  const [getRate, getShipmentRateResult] =  useQuery(GET_SHIPMENT_RATES, {
    onCompleted: data => {
      const { senderDetail, receiverDetail } = shipmentDetails;
    },
    onError: error => {},
  });
  const getShipmentRates = (shipmentDetail) => {
    getRate({
      variables: {
        shipmentDetail
      }
    });
    return [getShipmentRates, getShipmentRateResult];
}
}


const useGetUserQuery = () => {
  const userPayload = useGetUserPayload();
  const [shipmentDetails, setShipmentDetail] = useRecoilState(
    saveShipmentDetailsState
  );
  return useQuery(GET_USER, {
    variables: { sub: userPayload.sub },
    onCompleted: data => {
      const { fullName, email, phoneNumber } = data.user;
      setShipmentDetail({
        ...shipmentDetails,
        senderDetail: {
          ...shipmentDetails.senderDetail,
          name: fullName,
          email,
          phoneNumber
        }
      });
    },
    fetchPolicy: 'no-cache'
  });
};

const useGetUserLazyQuery = () => {
  const [shippingRoute] = useRecoilState(shippingRouteState);
  const userPayload = useGetUserPayload();
  const [shipmentDetails, setShipmentDetail] = useRecoilState(
    saveShipmentDetailsState
  );
  const [getUser, getUserResult] = useLazyQuery(GET_USER, {
    onCompleted: data => {
      const { fullName, email, phoneNumber } = data.user;
      setShipmentDetail({
        ...shipmentDetails,
        [shippingRoute === 'import' ? 'receiverDetail' : 'senderDetail']: {
          ...shipmentDetails[
            shippingRoute === 'import' ? 'receiverDetail' : 'senderDetail'
          ],
          name: fullName,
          email,
          phoneNumber
        }
      });
    },
    fetchPolicy: 'no-cache'
  });

  const getUserDetails = () => {
    getUser({ variables: { sub: userPayload.sub } });
  };

  return [getUserDetails, getUserResult];
};

const useGenerateTransactionReferenceQuery = () => {
  const [, setReference] = useRecoilState(transactionReferenceState);
  const sendAlert = useSendAlert();
  const [generateReference, generateReferenceResult] = useLazyQuery(
    GET_TRANSACTION_REFERENCE,
    {
      onCompleted: data => {
        const { id } = data.generateTransactionReference;
        setReference(id);
      },
      onError: error => {
        sendAlert(parseGraphqlError(error), 'error');
      }
    }
  );

  const generateTransactionReference = (id, transactionType) => {
    generateReference({
      variables: {
        transaction: {
          id,
          transactionType
        }
      }
    });
  };

  return [generateTransactionReference, generateReferenceResult];
};

const useGetWalletQuery = () => {
  const [, setWalletState] = useRecoilState(walletState);

  return useQuery(GET_WALLET, {
    onCompleted: data => {
      if (data) {
        const { id: walletId, totalReceived, totalSent } = data.getWallet;
        setWalletState({
          walletId,
          walletBalance: totalReceived - totalSent
        });
      }
    }
  });
};

const useGetTransactions = () => {
  return useQuery(GET_TRANSACTIONS, {
    onCompleted: data => {}
  });
};

const useGetTransactionsLazyQuery = () => {
  const sendAlert = useSendAlert();
  const [getTransactions, transactionsResults] = useLazyQuery(
    GET_TRANSACTIONS,
    {
      onError: error => sendAlert(parseGraphqlError(error), 'error')
    }
  );

  const getTransactionList = filter => {
    getTransactions({ variables: { filter } });
  };

  return [getTransactionList, transactionsResults];
};

const useGetShipmentsQuery = () => {
  return useQuery(GET_SHIPMENTS, {
    onCompleted: data => {}
  });
};

const useGetShipmentDetailsQuery = id => {
  return useQuery(GET_SHIPMENT_DETAILS, {
    variables: { shipmentId: id },
    onCompleted: data => {}
  });
};

const useGetSavedAddresses = query => {
  return useQuery(query || GET_SAVED_ADDRESSES, {
    onCompleted: data => {}
  });
};

const useGetShipmentRateQuery = (transactionType, destinationType) => {
  const [saveSenderAddress] = useRecoilState(saveSenderAddressState);
  const [saveReceiverAddress] = useRecoilState(saveReceiverAddressState);
  const [shipmentDetails] = useRecoilState(saveShipmentDetailsState);
  const [saveAddress] = useSaveAddressMutation();
  const [, setCurrentRates] = useRecoilState(saveShipmentRateState);
  const [requestPage, setRequestPage] = useRecoilState(shipmentRequestState);
  const sendAlert = useSendAlert();
  const [getRate, getShipmentRateResult] = useLazyQuery(GET_SHIPMENT_RATES, {
    onCompleted: data => {
      const { senderDetail, receiverDetail } = shipmentDetails;
      if (transactionType === 'shipment') {
        if (saveSenderAddress && destinationType === 'sender') {
          saveAddress(senderDetail);
        }
        if (saveReceiverAddress && destinationType === 'receiver') {
          saveAddress(receiverDetail);
        }
        setRequestPage(requestPage + 1);
      }
      setCurrentRates(data.getShipmentRate);
    },
    onError: error => {
      // sendAlert(parseGraphqlError(error), 'error');
      sendAlert(
        'The city selected is currently not shipped to, you could choose another city of close proximity or contact support',
        'error'
      );
      // if (destinationType === 'sender') {
      //   ReactGA.exception({
      //     description: 'Senders address not supported',
      //     fatal: true
      //   });
      // }
      // if (destinationType === 'receiver') {
      //   ReactGA.exception({
      //     description: 'Receivers address not supported',
      //     fatal: true
      //   });
      // }
    },
    fetchPolicy: "network-only"
  });

  const getShipmentRates = (shipmentDetail, destinationType) => {
    getRate({
      variables: {
        shipmentDetail
      }
    });
  };

  return [getShipmentRates, getShipmentRateResult];
};


const useTrackShipmentLazyQuery = () => {
  const sendAlert = useSendAlert();
  const [trackShipment, trackShipmentResult] = useLazyQuery(TRACK_SHIPMENT, {
    onCompleted: data => {},
    onError: error => {
      sendAlert(parseGraphqlError(error), 'error');
    }
  });

  const getTrackingDetails = trackingId => {
    trackShipment({
      variables: {
        trackingId
      }
    });
  };

  return [getTrackingDetails, trackShipmentResult];
};

const useTrackShipmentQuery = trackingId => {
  const sendAlert = useSendAlert();
  return useQuery(TRACK_SHIPMENT, {
    variables: {
      trackingId
    },
    onError: error => {
      sendAlert(parseGraphqlError(error), 'error');
    }
  });
};

const useGetBankListQuery = () => {
  return useQuery(GET_BANK_LIST, {
    onCompleted: data => {}
  });
};

const useGetBankAccountNameQuery = () => {
  const sendAlert = useSendAlert();
  const [getBankName, getBankNameResult] = useLazyQuery(GET_BANK_ACCOUNT_NAME, {
    onError: error => sendAlert(parseGraphqlError(error), 'error')
  });

  const getAccountName = bankDetail => {
    getBankName({
      variables: { bankDetail }
    });
  };
  return [getAccountName, getBankNameResult];
};

const useGetBankAccountListQuery = logs => {
  const sendAlert = useSendAlert();
  const [getLogs, logsResult] = useGetWithdrawalLogsLazyQuery();
  const [, setAllBanks] = useRecoilState(allBankDetailsState);
  return useQuery(GET_BANK_ACCOUNTS, {
    onCompleted: data => {
      const bankList = data.getUserBankDetails;
      setAllBanks(bankList);
      if (logs) {
        getLogs();
      }
    },
    onError: error => sendAlert(parseGraphqlError(error), 'error')
  });
};

const useGetBankAccountListLazyQuery = logs => {
  const sendAlert = useSendAlert();

  const [getAccountList, getAccountListResult] = useLazyQuery(
    GET_BANK_ACCOUNTS,
    {
      onError: error => sendAlert(parseGraphqlError(error), 'error')
    }
  );

  const getBankAccounts = () => {
    getAccountList();
  };
  return [getBankAccounts, getAccountListResult];
};

const useGetWithdrawalLogsLazyQuery = () => {
  const sendAlert = useSendAlert();
  const [getLogs, getLogsResult] = useLazyQuery(GET_WITHDRAWAL_LOGS, {
    onCompleted: data => {},
    onError: error => sendAlert(parseGraphqlError(error), 'error')
  });

  const getWithdrawalLogs = filter => {
    getLogs({ variables: { filter } });
  };

  return [getWithdrawalLogs, getLogsResult];
};

const useGetCountriesQuery = () => {
  return useQuery(GET_COUNTRIES, {
    onCompleted: data => {}
  });
};
const useGetCountriesLazyQuery = (locationType, shipmentRoute) => {
  const [getCountries, countriesResult] = useLazyQuery(GET_COUNTRIES, {
    onCompleted: data => {
      const countries = data.getCountries;
    }
  });

  const getCountriesList = () => {
    getCountries();
  };

  return [getCountriesList, countriesResult];
};

const useGetStatesQuery = (locationType, locationDataState) => {
  const sendAlert = useSendAlert();
  const [quotesLocation, setQuotesLocation] = useRecoilState(
    locationDataState || quotesLocationDataState
  );
  const [getStates, statesResult] = useLazyQuery(GET_STATES, {
    onError: error => sendAlert(parseGraphqlError(error), 'error'),
    onCompleted: data => {
      setQuotesLocation({
        ...quotesLocation,
        [locationType]: {
          ...quotesLocation[locationType],
          states: data.getStates
        }
      });
    }
  });

  const getStatesList = countryCode => {
    getStates({ variables: { countryCode } });
  };

  return [getStatesList, statesResult];
};

const useGetCitiesQuery = (locationType, locationDataState) => {
  const [quotesLocation, setQuotesLocation] = useRecoilState(
    locationDataState || quotesLocationDataState
  );
  const sendAlert = useSendAlert();
  const [getCities, citiesResult] = useLazyQuery(GET_CITIES, {
    onError: error => sendAlert(parseGraphqlError(error), 'error'),
    onCompleted: data => {
      setQuotesLocation({
        ...quotesLocation,
        [locationType]: {
          ...quotesLocation[locationType],
          cities: data.getCities
        }
      });
    }
  });

  const getCitiesList = countryCode => {
    getCities({ variables: { countryCode } });
  };

  return [getCitiesList, citiesResult];
};
export {
  useGetWalletBalanceQuery,
  useGenerateTransactionReferenceQuery,
  useGetUserQuery,
  useGetWalletQuery,
  useGetTransactions,
  useGetShipmentsQuery,
  useGetShipmentDetailsQuery,
  useGetSavedAddresses,
  useGetShipmentRateQuery,
  useTrackShipmentLazyQuery,
  useTrackShipmentQuery,
  useGetTransactionsLazyQuery,
  useGetBankListQuery,
  useGetBankAccountNameQuery,
  useGetBankAccountListQuery,
  useGetBankAccountListLazyQuery,
  useGetWithdrawalLogsLazyQuery,
  useGetCountriesQuery,
  useGetStatesQuery,
  useGetCitiesQuery,
  useGetCountriesLazyQuery,
  useGetUserLazyQuery,
  useChatGetShipmentRateQuery
};

import { useMutation } from '@apollo/react-hooks';
import { useRecoilState } from 'recoil';
import {
  CREATE_USER,
  LOGIN_USER,
  SAVE_SHIPMENT,
  PAY_FROM_WALLET,
  SAVE_ADDRESS,
  DELETE_ADDRESS,
  FORGOT_PASSWORD,
  UPDATE_PASSWORD,
  SAVE_BANK_DETAILS,
  DELETE_BANK_DETAILS,
  INITIATE_WITHDRAWAL,
  CONFIRM_WITHDRAWAL_REQUEST,
  SAVE_FEEDBACK
} from './definitions/mutations.def';
import {
  GET_SAVED_ADDRESSES,
  GET_BANK_ACCOUNTS
} from './definitions/queries.def';
import { useSendAlert } from '../utilities/send-alert';
import { parseGraphqlError } from '../utilities/parse-graphql-error';
import {
  loginState,
  showOtpState,
  feedbackOverlayState
} from '../recoil/atoms';
import { useAuthToken } from '../token';
import Client from '../services/client';

const useRegistrationMutation = () => {
  const sendAlert = useSendAlert();
  const [createUser, createUserResult] = useMutation(CREATE_USER, {
    onCompleted: data => {
    
    },
    onError: error => {
      sendAlert(parseGraphqlError(error), 'error');
    }
  });

  const register = data => {
    createUser({
      variables: { newUser: data }
    });
  };

  return [register, createUserResult];
};

const useLoginMutation = () => {
  // const history = useHistory();
  const sendAlert = useSendAlert();
  const [, setToken, , getToken] = useAuthToken();
  const [, setLoggedIn] = useRecoilState(loginState);
  const [loginUser, loginResult] = useMutation(LOGIN_USER, {
    onCompleted: data => {
      const {
        login: { accessToken }
      } = data;
      setToken(process.env.REACT_APP_TOKEN_KEY, accessToken);
      setLoggedIn(true);
      const session = getToken('unauthorizedSession', true);

      if (session && session.length > 1) {
        // history.push(session);
      }
      window.location.reload();
     
    },
    onError: error => {
      sendAlert(parseGraphqlError(error), 'error');
    }
  });

  const login = data => {
    loginUser({
      variables: { existingUser: data }
    });
  };

  return [login, loginResult];
};

const useSaveShipmentMutation = () => {
  const sendAlert = useSendAlert();
  const [saveShipment, saveShipmentResult] = useMutation(SAVE_SHIPMENT, {
    onCompleted: data => {
    
    },
    onError: error => {
      sendAlert(parseGraphqlError(error), 'error');
     
    }
  });

  const saveShipmentDetails = data => {
    saveShipment({
      variables: { shipmentDetails: data }
    });
  };

  return [saveShipmentDetails, saveShipmentResult];
};

const usePayFromWalletMutation = () => {
  const [, setShowFeedback] = useRecoilState(feedbackOverlayState);
  const sendAlert = useSendAlert();
  const [payFromWallet, payFromWalletResult] = useMutation(PAY_FROM_WALLET, {
    onCompleted: data => {
      setTimeout(() => {
        setShowFeedback(true);
      }, 2000);
    },
    onError: error => {
      sendAlert(parseGraphqlError(error), 'error');
    }
  });

  const payFromWalletById = id => {
    payFromWallet({
      variables: {
        detail: {
          shipmentId: id
        }
      }
    });
  };

  return [payFromWalletById, payFromWalletResult];
};

const useSaveAddressMutation = () => {
  const [saveAddress, saveAddressResults] = useMutation(SAVE_ADDRESS, {
    onCompleted: data => {
      const client = Client();
      client.resetStore();
    }
  });

  const saveCustomerAddress = address => {
    saveAddress({
      variables: { address }
    });
  };

  return [saveCustomerAddress, saveAddressResults];
};

const useDeleteAddressMutation = () => {
  const sendAlert = useSendAlert();
  const [deleteAddress, deleteAddressResults] = useMutation(DELETE_ADDRESS, {
    onCompleted: data => {
      sendAlert('Address deleted!');
      const client = Client();
      client.resetStore();
    },
    onError: error => {
      sendAlert(parseGraphqlError(error));
    },
    update: (cache, { data }) => {
      const allAddresses = cache.readQuery({
        query: GET_SAVED_ADDRESSES
      });
      const { list } = allAddresses.getAddresses;
      const updatedAddresses = list.filter(
        list => list.id !== data.deleteAddress.id
      );
      cache.writeQuery({
        query: GET_SAVED_ADDRESSES,
        data: { getAddresses: { list: updatedAddresses } }
      });
    }
  });

  const deleteCustomerAddress = id => {
    deleteAddress({
      variables: { id }
    });
  };

  return [deleteCustomerAddress, deleteAddressResults];
};

const useForgotPasswordMutation = () => {
  const sendAlert = useSendAlert();
  const [resetPassword, resetPasswordResult] = useMutation(FORGOT_PASSWORD, {
    onCompleted: () => {},
    onError: error => {
      sendAlert(parseGraphqlError(error), 'alert');
    }
  });

  const resetUserPassword = userData => {
    resetPassword({
      variables: { userData }
    });
  };

  return [resetUserPassword, resetPasswordResult];
};

const useUpdatePasswordMutation = token => {
  const sendAlert = useSendAlert();
  const [updatePassword, updatePasswordResult] = useMutation(UPDATE_PASSWORD, {
    onCompleted: () => {},
    onError: error => {
      sendAlert(parseGraphqlError(error), 'alert');
    },
    context: {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  });

  const updateUserPassword = password => {
    updatePassword({
      variables: { newPassword: password }
    });
  };

  return [updateUserPassword, updatePasswordResult];
};

const useSaveBankDetailsMutation = () => {
  const sendAlert = useSendAlert();
  const client = Client();
  const [saveAccount, saveAccountResult] = useMutation(SAVE_BANK_DETAILS, {
    onCompleted: () => {
      client.resetStore();
    },
    onError: error => sendAlert(parseGraphqlError(error), 'error')
  });

  const saveBankDetails = bankDetail => {
    saveAccount({ variables: { bankDetail } });
  };

  return [saveBankDetails, saveAccountResult];
};

const useDeleteBankDetailMutation = () => {
  const sendAlert = useSendAlert();
  const [deleteBankDetail, deleteBankDetailResults] = useMutation(
    DELETE_BANK_DETAILS,
    {
      onCompleted: data => {
        sendAlert('Bank account deleted!');
      },
      onError: error => {
        sendAlert(parseGraphqlError(error));
      },
      update: (cache, { data }) => {
        const client = Client();
        const allBankDetails = cache.readQuery({
          query: GET_BANK_ACCOUNTS
        });

        const list = allBankDetails.getUserBankDetails;

        const updatedBankAccounts = list.filter(
          list => list.id !== data.deleteBankDetail.id
        );
        cache.writeQuery({
          query: GET_BANK_ACCOUNTS,
          data: { getUserBankDetails: updatedBankAccounts }
        });
      }
    }
  );

  const deleteBankAccount = id => {
    deleteBankDetail({
      variables: { id }
    });
  };

  return [deleteBankAccount, deleteBankDetailResults];
};

const useInitiateWithdrawalMutation = () => {
  const sendAlert = useSendAlert();
  const [, setShowOtp] = useRecoilState(showOtpState);
  const [initiateWithdrawal, initiateWithdrawalResult] = useMutation(
    INITIATE_WITHDRAWAL,
    {
      onError: error => sendAlert(parseGraphqlError(error), 'error'),
      onCompleted: () => setShowOtp(true)
    }
  );

  const initiateWithdrawalRequest = withdrawInput => {
    initiateWithdrawal({
      variables: { withdrawInput }
    });
  };
  return [initiateWithdrawalRequest, initiateWithdrawalResult];
};

const useConfirmWithdrawalRequestMutation = () => {
  const sendAlert = useSendAlert();
  const [, setShowOtp] = useRecoilState(showOtpState);
  const [
    confirmWithdrawalRequest,
    confirmWithdrawalRequestResult
  ] = useMutation(CONFIRM_WITHDRAWAL_REQUEST, {
    onError: error => sendAlert(parseGraphqlError(error), 'error'),
    onCompleted: () => setShowOtp(false)
  });

  const confirmWithdrawalRequestRequest = confirmationInput => {
    confirmWithdrawalRequest({
      variables: { confirmationInput }
    });
  };
  return [confirmWithdrawalRequestRequest, confirmWithdrawalRequestResult];
};

const useSaveFeedbackMutation = () => {
  const sendAlert = useSendAlert();
  const [, setShowFeedback] = useRecoilState(feedbackOverlayState);
  const [saveFeedback, saveFeedbackResults] = useMutation(SAVE_FEEDBACK, {
    onCompleted: data => {
      setShowFeedback(false);
    },
    onError: error => sendAlert(parseGraphqlError(error), 'error')
  });

  const saveCustomerFeedback = feedback => {
    saveFeedback({
      variables: { feedback }
    });
  };

  return [saveCustomerFeedback, saveFeedbackResults];
};
export {
  useRegistrationMutation,
  useLoginMutation,
  useSaveShipmentMutation,
  usePayFromWalletMutation,
  useSaveAddressMutation,
  useDeleteAddressMutation,
  useForgotPasswordMutation,
  useUpdatePasswordMutation,
  useSaveBankDetailsMutation,
  useDeleteBankDetailMutation,
  useInitiateWithdrawalMutation,
  useConfirmWithdrawalRequestMutation,
  useSaveFeedbackMutation
};

import { DashboardShell, Loader } from "../../../../common/components";
import TopNav from "../../../../common/components/Layout/Dashboard/TopNav";
import BusyOverlay from '../../../../components/busy-overlay';
import send from "../../../../static/images/send.png";
import { usePaystackPayment } from 'react-paystack';
import React, { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useGetUserPayload } from '../../../../utilities/get-payload';
import numberWithComma from '../../../../utilities/number-with-commas'
import {
	useGetWalletBalanceQuery,
	useGetUserQuery,
	useGetUserLazyQuery
  } from '../../../../operations/queries';
  import {
	saveShipmentDetailsState,
  } from '../../../../recoil/atoms';
import ApolloClient from 'apollo-boost';
import gql from 'graphql-tag';
import { shuffleToken } from '../../../../authorization';
import { useAuthToken } from '../../../../token';
import Client from '../../../../services/client';
import {GET_SHIPMENT_RATES} from '../../../../operations/definitions/queries.def'
import {SAVE_SHIPMENT, PAY_FROM_WALLET} from '../../../../operations/definitions/mutations.def'



const Index = () => {

	const messagesEndRef = React.createRef()

	const userPayload = useGetUserQuery();
	const userData = useGetUserPayload();
	const walletBalance = useGetWalletBalanceQuery();
	const [authToken] = useAuthToken();
	const client = Client();


	const [input, setInput] = useState('');
	const [arrayData, setArrayData] = useState(false);
	const [loading, setLoading] = useState(false);
	const [amount, setAmount] = useState(500000);
	const [mode, setMode] = useState('');
	const [chats, setChat] = useState([{
		message:'Hi 👋 I’m Tobi From Topship',
		sender: 'admin'
	}, {
		message: `Welcome ${userData.fullName.split(' ')[0]} 😁`,
		sender: 'admin'
	}, {
		message: 'What would you like to do today? ',
		sender: 'admin'
	}, {
		message: 'To get a shipping quote Send 1 below',
		sender: 'admin'
	},  {
		message: 'To book shipment Send 2 Below',
		sender: 'admin'
	}, {
		message: 'To check your wallet balance Send 3 Below',
		sender: 'admin'
	}, 
	{
		message: 'For A Quick Top up Send 4 Below',
		sender: 'admin'
	},
	{
		message: 'Type 5 To Speak to an agent',
		sender: 'admin'
	},
]);
	const [userChat, setUserChat] = useState([]);
	const [rates, setRates] = useState('');
	const [command, setCommand] = useState('');
	


	const handleInput = async (data) => {
        setInput(data.target.value);
	}
	
	const onKeyPressHandler = (event)=> {
		if (event.key === 'Enter') {
			handleSubmit(event)
		}
		else {

		}
	};
	const handleSubmit =  event => {
		event.preventDefault();
		userEntry();
	}

	const processInput = async (value) => {
		switch (value.length == 1 && !isNaN(Number(value))) {
            case (true):
             return numberCommands(value.trim());
			case (false):
			  return textCommands(value.trim());
            default:
              return numberCommands(value.trim());
          }

	}

	const numberCommands = async (number) => {
		switch (number) {
            case "1":
              return adminEntry('What quote would you like to get today? e.g Quote 2kg from Surulere NG to New York US');
			case "2":
              return adminEntry('Do you want to make a Single Shipment Order or Bulk Shipment Order?');
			case "3":
              return adminEntry(`Your wallet balance is  ₦${numberWithComma(Math.round(walletBalance.data.getWalletBalance.totalBalance / 100))}`);
			case "4":
              return await paymentTrigger();
			case "5":
              return adminEntry('Please click on link to talk to our agent');
            default:
              return adminEntry('Would you like to speak to a customer care agent directly?');
          }
	}

	const textCommands = async (value) => {
		try {
		const parsedText =  await parseCommand(value);
		switch (parsedText) {
            case 'single':
              return adminEntry('What would you like to ship today?');
			case 'bulk':
              return (
				await adminEntry('Where would you like to ship today? e.g. \nShipping Details  \nWeight:2kg \nQuantity:2 \nDescription:Women Dresses \nCollection Mode: Drop Off \nPricingtier:Express \nPickup Address: 268 Herbert Macaulay Way \nPickup City:Yaba \nPickup State:Lagos \nPickup Country:Nigeria \nReciever Email Address:hello@topship.africa \nReciever Phone number:07087517515 \nReciever Name:Topship Africa \nReciever Address:270 Murtala Mohammed Way \nReciever City:Yaba \nReciever State:Lagos \nReciever Country:Nigeria \nReciever Postal Code:23401 \nPlease write in this order. Thank you'),
				await adminEntry('Shipping Details \nWeight: \nQuantity: \nDescription: \nCollection Mode: \nPricingtier: \nPickup Address: \nPickup City: \nPickup State: \nPickup Country: \nReciever Email Address: \nReciever Phone number: \nReciever Name: \nReciever Address: \nReciever City: \nReciever State: \nReciever Country: \nReciever Postal Code:'));
			case 'quote':
              return await viewQuote(value);
			case 'shipment':
              return (
				await adminEntry('We have received your Shipment Details received! System is currently processing, please hold on'),
				await createShipment(value)
			  );
			case 'yes':
				return await openWhatsapp()
            default:
              return await adminEntry('Would you like to speak to a customer care agent directly???');
          }
		}
		catch(e) {
			console.log(e)
		}
	}

	


	const parseCommand = async (input) => {
		const wordArray = input.split(" ");
		switch (wordArray[0].toLowerCase()) {
            case "quote":
              return wordArray[0].toLowerCase();
			case "single":
			  return wordArray[0].toLowerCase();
			case "bulk":
			  return wordArray[0].toLowerCase();
			case "yes":
			 return wordArray[0].toLowerCase();
			case "shipment":
			 return wordArray[0].toLowerCase();
			default :
			 return ''
		}
		
	}
	const viewQuote = async(data) => {
		setLoading(true)
		const parsedRequest  = await parseQuoteRequest(data);
        const shipmentDetail = {
            senderDetails: {
                cityName: parsedRequest.from,
                countryCode: parsedRequest.senderCountry
            },
            receiverDetails: {
                cityName: parsedRequest.toState,
                countryCode: parsedRequest.receiverCountry
            },
            totalWeight: parseFloat(parsedRequest.weight, 10)
        };

         const rates =  await client.query({
              query: GET_SHIPMENT_RATES,
              variables: { shipmentDetail},
            })
            .then((response) => {
                return response.data
            })
            .catch((err) => {
                adminEntry(err.message)
				setLoading(false)
            });
            if(rates){
			rates.getShipmentRate.map(async(rate)=> {
				return await adminEntry(`It will cost ₦${rate.cost/100} to ship ${parsedRequest.weight}KG from ${parsedRequest.from} to ${parsedRequest.toState} ${rate.mode} mode`)
			})
			setLoading(false)
		}

		else {
			setLoading(false)	

		}

	}

	const openWhatsapp = async() => {
		window.open("https://api.whatsapp.com/send?phone=2349080777728", "_blank")
	}



	const paymentTrigger = async() => {
		adminEntry('Please wait while we create a funding request');
         initializePayment(onSuccess, onClose);
	}

	const parseQuoteRequest = async (data) => {
		const unparsedText = data
		const wordArray = unparsedText.split(" ");
		const weight = wordArray[1].replace(/\D/g, '');
		const from = wordArray[3];
		const receiverCountry = wordArray[wordArray.length - 1];
		const toState = unparsedText.slice(
			unparsedText.indexOf('to') + 2,
			unparsedText.lastIndexOf(receiverCountry),
		  ).trim();
		const senderCountry = wordArray[4];
		return {
			weight,
			from,
			toState,
			senderCountry,
			receiverCountry
		}
	
	}

	const createShipment = async() => {
		const parsedRequest  = await parseShipment(input);
        
        setLoading(true)
        const shipmentDetail = {
            senderDetails: {
                cityName: parsedRequest.PickupCity,
                countryCode: 'NG'
            },
            receiverDetails: {
                cityName: parsedRequest.RecieverCity,
                countryCode: 'US'
            },
            totalWeight: parseFloat(parsedRequest.Weight, 10)
        };
         const rates =  await client.query({
              query: GET_SHIPMENT_RATES,
              variables: { shipmentDetail},
            })
            .then((response) => {
                return response.data
            })
            .catch((err) => console.error(err));
        

            const senderDetails = {
                addressLine1: parsedRequest.PickupAddress,
                city: parsedRequest.PickupCity,
                country: parsedRequest.PickupCountry,
                countryCode: "NG",
                email: userPayload.data.user.email,
                name: userPayload.data.user.fullName,
                phoneNumber: userPayload.data.user.phoneNumber,
                state: parsedRequest.PickupState
            }
            const reciverDetails = {
                addressLine1: parsedRequest.RecieverAddress,
                city: parsedRequest.RecieverCity,
                country: parsedRequest.RecieverCountry,
                countryCode: "US",
                email: parsedRequest.RecieverEmailAddress,
                name: parsedRequest.RecieverName,
                phoneNumber: parsedRequest.RecieverPhoneNumber,
                postalCode: parsedRequest.RecieverPostalCode,
                state: parsedRequest.RecieverState
            }
        const shipmentDetails = {
            items: [{
                category: "ClothingAndTextile",
                description: parsedRequest.Description,
                quantity: parseFloat(parsedRequest.Quantity),
                value: 999700,
                weight: parseFloat(parsedRequest.Weight)
            }],
            itemCollectionMode: parsedRequest.CollectionMode,
            pricingTier: parsedRequest.PricingTier,
            insuranceType: "None",
            insuranceCharge: 0,
            discount: 0,
            shipmentRoute: "Export",
            shipmentCharge: rates.getShipmentRate[0].cost,
            pickupCharge: 0,
            senderDetail: senderDetails,
            receiverDetail: reciverDetails
        }
        const shipmentOrder = await client.mutate({
            mutation: SAVE_SHIPMENT,
            variables: {shipmentDetails},
          })
          .then((response) => {
            return response.data
        })
          .catch((err) => {
            const tex = {
                message:err.message,
                sender: 'admin'
            }
            chats.push(tex);
            setChat(chats);
          });

          const shipmentId = shipmentOrder.saveShipment[0].id;
          const detail = {
              shipmentId
          }

          const payment = await client.mutate({
              mutation: PAY_FROM_WALLET,
              variables: {detail}
          })
          .then((response) => {
            return response.data
        })
          .catch((err) => console.error(err));
          const tex = {
            message:`Thank you for your request on Topship. Your Request has been successfully booked and your order number is ${shipmentOrder.saveShipment[0].trackingId}`,
            sender: 'admin'
        }
        chats.push(tex);
        setChat(chats);
        setLoading(false)
        
	}

	const parseShipment = async (data) => {
		const unparsedText = data;
		// const regex = /([^:\s]+):([^:\s]+)/g;
		// m = regex.exec(data)
	
		const Weight = unparsedText.substring(
			unparsedText.indexOf("Weight:") + 7, 
			unparsedText.lastIndexOf("kg")
		).trim();
		const Quantity = unparsedText.substring(
			unparsedText.indexOf("Quantity:") + 9, 
			unparsedText.lastIndexOf("Description")
		).trim();
		const Description = unparsedText.substring(
			unparsedText.indexOf("Description:") + 12, 
			unparsedText.lastIndexOf("Collection Mode")
		).trim();
		const CollectionMode = unparsedText.substring(
			unparsedText.indexOf("Collection Mode:") + 16, 
			unparsedText.lastIndexOf("Pricing")
		).trim();
		const PricingTier = unparsedText.substring(
			unparsedText.indexOf("Pricing Tier:") + 13, 
			unparsedText.lastIndexOf("Pickup Address")
		).trim();
		const PickupAddress = unparsedText.substring(
			unparsedText.indexOf("Pickup Address:") + 15, 
			unparsedText.lastIndexOf("Pickup City")
		).trim();
		const PickupCity = unparsedText.substring(
			unparsedText.indexOf("Pickup City:") + 12, 
			unparsedText.lastIndexOf("Pickup State")
		).trim();
		const PickupState = unparsedText.substring(
			unparsedText.indexOf("Pickup State:") + 13, 
			unparsedText.lastIndexOf("Pickup Country")
		).trim();
		const PickupCountry = unparsedText.substring(
			unparsedText.indexOf("Pickup Country:") + 15, 
			unparsedText.lastIndexOf("Reciever Email Address")
		).trim();
		const RecieverEmailAddress = unparsedText.substring(
			unparsedText.indexOf("Reciever Email Address:") + 23, 
			unparsedText.lastIndexOf("Reciever Phone Number")
		).trim();
		const RecieverPhoneNumber = unparsedText.substring(
			unparsedText.indexOf("Reciever Phone Number:") + 22, 
			unparsedText.lastIndexOf("Reciever Name")
		).trim();
		const RecieverName = unparsedText.substring(
			unparsedText.indexOf("Reciever Name:") + 14, 
			unparsedText.lastIndexOf("Reciever Address")
		).trim();
		const RecieverAddress = unparsedText.substring(
			unparsedText.indexOf("Reciever Address:") + 17, 
			unparsedText.lastIndexOf("Reciever City")
		).trim();
		const RecieverCity = unparsedText.substring(
			unparsedText.indexOf("Reciever City:") + 14, 
			unparsedText.lastIndexOf("Reciever State")
		).trim();
		const RecieverState = unparsedText.substring(
			unparsedText.indexOf("Reciever State:") + 15, 
			unparsedText.lastIndexOf("Reciever Country")
		).trim();
		const RecieverCountry = unparsedText.substring(
			unparsedText.indexOf("Reciever Country:") + 17, 
			unparsedText.lastIndexOf("Reciever Postal Code")
		).trim();
		const RecieverPostalCode = unparsedText.substring(
			unparsedText.indexOf("Reciever Postal Code:") + 21
		).trim();
		

		return {
			Weight,
			Quantity,
			Description, 
			 CollectionMode,
			PricingTier,
			 PickupAddress, 
			 PickupCity,
			 PickupState,
			 PickupCountry, 
			 RecieverEmailAddress,
			 RecieverPhoneNumber,
			 RecieverName,
			 RecieverAddress, 
			 RecieverCity, 
			 RecieverState,
			 RecieverCountry, 
			 RecieverPostalCode,
		}
	}

	const userEntry = async () => {
		const text = {
			message:input,
			sender: 'user'
		}
		let newChat = [...chats, text];
		setChat(newChat);
		setUserChat(newChat.filter(c => c.sender == 'user'))
		setInput('');
		scrollToBottom();
	}

	const adminEntry = async (entry) => {
		const text = {
			message: entry,
			sender: 'admin'
		}
		let newChat = [...chats, text]
		setChat(newChat);
		setInput('');
	}

	const scrollToBottom = () => {
		messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
	  }

	  const endChat = async() => {
		const openingTexts = [{
			message: 'What next would you like to do today? ',
			sender: 'admin'
		}, {
			message: 'To get a shipping quote Send 1 below',
			sender: 'admin'
		},  {
			message: 'To book shipment Send 2 Below',
			sender: 'admin'
		}, {
			message: 'To check your wallet balance Send 3 Below',
			sender: 'admin'
		}, 
		{
			message: 'For A Quick Top up Send 4 Below',
			sender: 'admin'
		},
		{
			message: 'Type 5 To Speak to an agent',
			sender: 'admin'
		},
	]

		await openingTexts.map((text)=> {
			chats.push(text);
			setChat(chats);
		})
	  }

	  const config = {
		reference: Math.floor(1000000000000000 + Math.random() * 9000000000000000),
		email: userData.username,
		amount: amount,
		publicKey: process.env.REACT_APP_PAYSTACK_PUB_KEY
	  };

  const initializePayment = usePaystackPayment(config);
	  const onSuccess = reference => {
		const text = {
			message:'Topup successful!',
			sender: 'admin'
		}
		chats.push(text);
		setChat(chats);
		setInput('');
		endChat()
	  };
	
	  const onClose = () => {
		const text = {
			message:'Topup failed',
			sender: 'admin'
		}
		chats.push(text);
		setChat(chats);
		setInput('');
		endChat();
	  };

	useEffect(() => {
		if(userChat.length){
			processInput(userChat[userChat.length - 1].message)
		}
	}, [userChat])

	return (
		<DashboardShell>
			<div
				className="justify-between md:px-10"
				style={{
					position: "fixed",
					top: 0,
					left: 0,
					right: 0,
					zIndex: 2,
				}}
			>
				<TopNav/>
				<div className="body">
					<div className="chat-body">
					{ chats && chats.map((chat, index) => {
						return chat.sender === 'admin' ?
						<div className="messagediv" key={index}>
						<div className="message"><p>{chat.message}</p>
						</div>
					</div>
					 :
					 <div className="mymessagediv" key={index}>
					 <div className="mymessage">
						 <p>{chat.message}</p>
						 </div>
						 </div>
						})
						}
						
					</div>
					<div ref={messagesEndRef} />
				
				<div className="bottom-nav">
					<div className="chat">
					<div className="inputbox">
					<textarea  className="chatinput" type="text" placeholder="Type your message" onChange={evt => handleInput(evt)} value={input}  onKeyPress={(e) =>onKeyPressHandler(e)}/>
					</div>
					
					</div>
						<div className="send">
					<div className="sendicon" onClick={(e) => handleSubmit(e)}>
					<img
				style={{ width: 20, height: 20 }}
				src={send}
			/>
					</div>
					</div>
				
				</div>
				</div>

				

				
				</div>
				<BusyOverlay loading={loading} />
				
		</DashboardShell>
	);
}

export default Index;

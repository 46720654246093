// import React from "react";
// import { Router} from "react-router";
// import { BrowserRouter, Switch, Route } from 'react-router-dom';
// import Routes from "./common/components/Routes";
// import history from "./common/config/history";
import React, { Fragment } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Route, Switch } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import AlertTemplate from 'react-alert-template-basic';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import Chat from'./modules/dashboard/pages/ChatPage/index';
import Login from'./modules/auth/index';
import { ProtectedRoute } from './config/protected-route';

const queryClient = new QueryClient();

function App() {
	const options = {
		position: positions.TOP_RIGHT,
		timeout: 10000,
		offset: '20px',
		// you can also just use 'scale's
		transition: transitions.SCALE
	  };
	
	return (
		<div className='App'>
			 <QueryClientProvider client={queryClient}>
        <RecoilRoot>
          <AlertProvider template={AlertTemplate} {...options}>
            <Fragment>
              <Switch>
			<Route exact path='/' component={Login}></Route>
			<ProtectedRoute
                  exact
                  path='/chat'
                  component={Chat}
                ></ProtectedRoute>
			{/* <Route exact path='/chat' component={Chat}></Route> */}
			</Switch>
			</Fragment>
			</AlertProvider>
			</RecoilRoot>
			</QueryClientProvider>
			</div>
  );
}

export default App;

import gql from 'graphql-tag';

const CREATE_USER = gql`
  mutation createNewUser($newUser: RegistrationInput!) {
    signup(registrationInput: $newUser) {
      id
    }
  }
`;

const LOGIN_USER = gql`
  mutation loginUser($existingUser: LoginInput!) {
    login(loginInput: $existingUser) {
      accessToken
    }
  }
`;

const SAVE_SHIPMENT = gql`
  mutation saveShipment($shipmentDetails: [AddShipmentInput!]!) {
    saveShipment(shipment: $shipmentDetails) {
      id
      trackingId
      isPaid
      shipmentStatus
      user {
        id
        topshipId
        fullName
      }
      transaction {
        id
        finalBalance
      }
    }
  }
`;

const PAY_FROM_WALLET = gql`
  mutation payFromWallet($detail: PayFromWalletInput) {
    payFromWallet(detail: $detail) {
      id
      isPaid
    }
  }
`;

const SAVE_ADDRESS = gql`
  mutation saveAddress($address: AddAddressInput!) {
    saveAddress(address: $address) {
      state
    }
  }
`;

const DELETE_ADDRESS = gql`
  mutation deleteAddress($id: String!) {
    deleteAddress(id: $id) {
      id
    }
  }
`;

const FORGOT_PASSWORD = gql`
  mutation forgotPassword($userData: ForgotPasswordInput!) {
    forgotPassword(forgotPasswordInput: $userData) {
      fullName
    }
  }
`;

const UPDATE_PASSWORD = gql`
  mutation updatePassword($newPassword: String!) {
    updatePassword(newPassword: $newPassword) {
      id
    }
  }
`;

const SAVE_BANK_DETAILS = gql`
  mutation saveBankDetail($bankDetail: SaveBankDetailInput!) {
    saveBankDetail(bankDetail: $bankDetail) {
      id
    }
  }
`;

const DELETE_BANK_DETAILS = gql`
  mutation deleteBankDetail($id: String!) {
    deleteBankDetail(id: $id) {
      id
    }
  }
`;

const INITIATE_WITHDRAWAL = gql`
  mutation initiateWithdrawal($withdrawInput: WithdrawInput!) {
    initiateWithdrawal(withdrawInput: $withdrawInput) {
      status
      data {
        id
      }
    }
  }
`;

const CONFIRM_WITHDRAWAL_REQUEST = gql`
  mutation confirmWithdrawalRequest($confirmationInput: ConfirmationInput!) {
    confirmWithdrawalRequest(confirmationInput: $confirmationInput) {
      status
      message
    }
  }
`;

const SAVE_FEEDBACK = gql`
  mutation saveFeedback($feedback: SaveFeedbackInput!) {
    saveFeedback(feedback: $feedback) {
      rating
    }
  }
`;

export {
  CREATE_USER,
  LOGIN_USER,
  PAY_FROM_WALLET,
  SAVE_SHIPMENT,
  SAVE_ADDRESS,
  DELETE_ADDRESS,
  FORGOT_PASSWORD,
  UPDATE_PASSWORD,
  SAVE_BANK_DETAILS,
  DELETE_BANK_DETAILS,
  INITIATE_WITHDRAWAL,
  CONFIRM_WITHDRAWAL_REQUEST,
  SAVE_FEEDBACK
};

import { Avatar, Dropdown, Menu, message, Button } from "antd";
import React from "react";
import Logo from "../../../Logo";
import user from "../../../../../static/images/icons/user.png"
import caret from "../../../../../static/images/icons/caret.png"
import chat from "../../../../../static/images/icons/Chat.png"

export interface TopNavProps {
}

const TopNav: React.FunctionComponent<TopNavProps> = ({}) => {
	
	return (
		<div className="py-2 px-5 md:px-10 full-width flex flex-row align-items-center justify-between top-nav">
			<div className="menulogo"></div>
			<div className="menudetails">
<div className="menuname">Tobi From Topship</div>
<div className="menustatus">Online <span className="logged-in">●</span></div>
</div>
			{/* <div className="top-left flex flex-row">
			<Logo />
			</div> */}

			<div className="top-right flex flex-row">
			{/* <Button className="btn btn-grey inline-block">
            <img style={{ width: 13, height: 13, marginRight:5}} src={chat}/>
            <span>
            Get Help
            </span>
			<img style={{ width: 13, height: 13, marginRight:5}} src={caret}/>
            </Button>
			<Button className="btn btn-grey inline-block">
            <img style={{ width: 13, height: 13, marginRight:5}} src={user}/>
            <span>
            Your Account
            </span>
			<img style={{ width: 13, height: 13, marginRight:5}} src={caret}/>
            </Button> */}
			</div>
		</div>
	);
};

export default TopNav;

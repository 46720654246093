import React, { useState } from 'react';
import ShowPassword from '../assets/svg/eye-open.svg';
import HidePassword from '../assets/svg/eye-close.svg';

const PasswordInput = ({ id, name, onChange, value, disabled }) => {
  const [displayPassword, setDisplayPassword] = useState(false);

  return (
    <div className='password-control'>
      <input
        type={displayPassword ? 'text' : 'password'}
        id={id}
        name={name}
        onChange={onChange}
        value={value}
        disabled={disabled}
      />
      <img
        className='hide-show-password'
        onClick={() => setDisplayPassword(!displayPassword)}
        src={displayPassword ? `${ShowPassword}` : `${HidePassword}`}
        alt='Show or Hide Password'
      />
    </div>
  );
};

export default PasswordInput;

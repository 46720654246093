import { atom } from 'recoil';
const loginState = atom({
  key: 'loginState',
  default: false
});

const shipmentRequestState = atom({
  key: 'shipmentRequestState',
  default: 0
});

const blackFridayState = atom({
  key: 'blackFridayState',
  default: false
});

const selectedBlackFridayState = atom({
  key: 'selectedBlackFridayState',
  default: {}
});

const shipmentPaymentState = atom({
  key: 'shipmentPaymentState',
  default: 0
});

const quoteRequestState = atom({
  key: 'quoteRequestState',
  default: 0
});

const selectedRateState = atom({
  key: 'selectedRateState',
  default: {}
});

const shipmentTypeState = atom({
  key: 'shipmentTypeState',
  default: {}
});

const shipmentItemsState = atom({
  key: 'shipmentItemsState',
  default: [
    {
      category: '',
      weight: '',
      quantity: '',
      value: '',
      description: ''
    }
  ]
});

const selectedInsurancePlan = atom({
  key: 'selectedInsurancePlan',
  default: {}
});

const selectedShippingMethod = atom({
  key: 'selectedShippingMethod',
  default: {}
});

const selectedPaymentMethod = atom({
  key: 'selectedPaymentMethod',
  default: {
    value: ''
  }
});

const selectedWalletFundingOption = atom({
  key: 'selectedWalletFundingOption',
  default: {}
});

const transactionReferenceState = atom({
  key: 'transactionReferenceState',
  default: null
});

const saveShipmentDetailsState = atom({
  key: 'saveShipmentDetailsState',
  default: {
    itemCollectionMode: 'DropOff',
    senderDetail: {},
    receiverDetail: {},
    items: [],
    pricingTier: '',
    insuranceType: '',
    insuranceCharge: 0,
    pickupCharge: 0,
    shipmentCharge: 0
  }
});

const saveShipmentRateState = atom({
  key: 'saveShipmentRate',
  default: []
});

const shipmentRateInformationState = atom({
  key: 'saveShipmentRateInformation',
  default: {}
});

const walletState = atom({
  key: 'walletState',
  default: {
    walletId: '',
    walletBalance: 0
  }
});

const busyOverlayState = atom({
  key: 'busyOverlayState',
  default: false
});

const termsOverlayState = atom({
  key: 'termsOverlayState',
  default: false
});

const feedbackOverlayState = atom({
  key: 'feedbackOverlayState',
  default: false
});

const senderAddress = atom({
  key: 'senderAddress',
  default: {}
});
const receiverAddress = atom({
  key: 'receiverAddress',
  default: {}
});

const quotesSenderAddress = atom({
  key: 'quotesSenderAddress',
  default: {}
});
const quotesReceiverAddress = atom({
  key: 'quotesReceiverAddress',
  default: {}
});

const quotesAddressesState = atom({
  key: 'quotesAddressesState',
  default: {
    receiver: {},
    sender: {}
  }
});
const quotesWeight = atom({
  key: 'quotesWeight',
  default: null
});

// local & exports quotes state

const localAndExportsQuotesSenderAddress = atom({
  key: 'localAndExportsQuotesSenderAddress',
  default: {}
});
const localAndExportsQuotesReceiverAddress = atom({
  key: 'localAndExportsQuotesReceiverAddress',
  default: {}
});

const localAndExportsQuotesAddressesState = atom({
  key: 'localAndExportsQuotesAddressesState',
  default: {
    receiver: {},
    sender: {}
  }
});
const localAndExportsQuotesWeight = atom({
  key: 'localAndExportsQuotesWeight',
  default: null
});

const localAndExportsQuotesLocationDataState = atom({
  key: 'localAndExportsQuotesLocationDataState',
  default: {
    sender: {
      countries: [],
      states: [],
      cities: []
    },
    receiver: {
      countries: [],
      states: [],
      cities: []
    }
  }
});

// imports quotes state

const importsQuotesSenderAddress = atom({
  key: 'importsQuotesSenderAddress',
  default: {}
});
const importsQuotesReceiverAddress = atom({
  key: 'importsQuotesReceiverAddress',
  default: {}
});

const importsQuotesAddressesState = atom({
  key: 'importsQuotesAddressesState',
  default: {
    receiver: {},
    sender: {}
  }
});
const importsQuotesWeight = atom({
  key: 'importsQuotesWeight',
  default: null
});

const importsQuotesLocationDataState = atom({
  key: 'localAndExportsQuotesLocationDataState',
  default: {
    sender: {
      countries: [],
      states: [],
      cities: []
    },
    receiver: {
      countries: [],
      states: [],
      cities: []
    }
  }
});

const saveSenderAddressState = atom({
  key: 'saveSenderAddressState',
  default: false
});

const saveReceiverAddressState = atom({
  key: 'saveReceiverAddressState',
  default: false
});

const shipmentSenderAddressVerified = atom({
  key: 'shipmentSenderAddressVerified',
  default: false
});

const warningState = atom({
  key: 'warningState',
  default: false
});

const receiverDetailsSchema = atom({
  key: 'receiverDetailsSchema',
  default: {
    name: {
      required: true,
      minWords: 2,
      maxWords: 2,
      maxLength: 20,
      label: 'Name'
    },
    email: {
      required: true,
      inputType: 'email',
      label: 'Email'
    },
    phoneNumber: {
      required: true,
      maxLength: 15,
      minLength: 9,
      maxWords: 1,
      label: 'Phone Number'
    },
    addressLine1: {
      required: true,
      maxLength: 45,
      label: 'Address'
    },
    addressLine2: {
      required: false,
      maxLength: 45,
      label: 'Address'
    },
    addressLine3: {
      required: false,
      maxLength: 45,
      label: 'Address'
    },
    city: {
      required: true,
      maxLength: 35,
      label: 'City'
    },
    state: {
      required: true,
      maxLength: 35,
      label: 'State'
    },
    country: {
      required: true,
      maxLength: 35,
      label: 'Country'
    },
    postalCode: {
      required: true,
      label: 'Postal Code'
    }
  }
});

const senderDetailSchema = atom({
  key: 'senderDetailSchema',
  default: {
    name: {
      required: true,
      minWords: 2,
      maxWords: 2,
      maxLength: 20,
      label: 'Name'
    },
    email: {
      required: true,
      inputType: 'email',
      label: 'Email'
    },
    phoneNumber: {
      required: true,
      maxLength: 15,
      minLength: 9,
      maxWords: 1,
      label: 'Phone Number'
    },
    addressLine1: {
      required: true,
      maxLength: 45,
      label: 'Address'
    },
    addressLine2: {
      required: false,
      maxLength: 45,
      label: 'Address'
    },
    addressLine3: {
      required: false,
      maxLength: 45,
      label: 'Address'
    },
    city: {
      required: true,
      maxLength: 35,
      label: 'City'
    },
    state: {
      required: true,
      maxLength: 35,
      label: 'State'
    },
    country: {
      required: true,
      maxLength: 35,
      label: 'Country'
    },
    postalCode: {
      required: true,
      label: 'Postal Code'
    }
  }
});

const transactionState = atom({
  key: 'transactionState',
  default: { currentPage: 1, list: [] }
});

const showOtpState = atom({
  key: 'showOtpState',
  default: false
});

const showBankListState = atom({
  key: 'showBankListState',
  default: false
});

const showErrorOverlayState = atom({
  key: 'showErrorOverlayState',
  default: true
});

const showTooltipOverlayState = atom({
  key: 'showTooltipOverlayState',
  default: {
    show: false,
    content: {}
  }
});

const allBankDetailsState = atom({
  key: 'allBankDetailsState',
  default: []
});

const quotesLocationDataState = atom({
  key: 'quotesLocationDataState',
  default: {
    sender: {
      countries: [],
      states: [],
      cities: []
    },
    receiver: {
      countries: [],
      states: [],
      cities: []
    }
  }
});
const bonusDiscountAmountState = atom({
  key: 'bonusDiscountAmountState',
  default: 0
});

const shippingRouteState = atom({
  key: 'shippingRouteState',
  default: 'export'
});

const shippingTermsAndConditionsState = atom({
  key: 'shippingTermsAndConditionsState',
  default: false
});

export {
  loginState,
  shipmentRequestState,
  shipmentPaymentState,
  quoteRequestState,
  selectedRateState,
  shipmentTypeState,
  shipmentItemsState,
  selectedInsurancePlan,
  selectedShippingMethod,
  selectedPaymentMethod,
  selectedWalletFundingOption,
  transactionReferenceState,
  saveShipmentDetailsState,
  walletState,
  saveShipmentRateState,
  shipmentRateInformationState,
  busyOverlayState,
  termsOverlayState,
  feedbackOverlayState,
  senderAddress,
  receiverAddress,
  quotesReceiverAddress,
  quotesSenderAddress,
  quotesWeight,
  saveSenderAddressState,
  saveReceiverAddressState,
  shipmentSenderAddressVerified,
  warningState,
  receiverDetailsSchema,
  senderDetailSchema,
  transactionState,
  showOtpState,
  showBankListState,
  showErrorOverlayState,
  allBankDetailsState,
  quotesAddressesState,
  quotesLocationDataState,
  bonusDiscountAmountState,
  shippingRouteState,
  localAndExportsQuotesSenderAddress,
  localAndExportsQuotesReceiverAddress,
  localAndExportsQuotesAddressesState,
  localAndExportsQuotesWeight,
  localAndExportsQuotesLocationDataState,
  importsQuotesSenderAddress,
  importsQuotesReceiverAddress,
  importsQuotesAddressesState,
  importsQuotesWeight,
  importsQuotesLocationDataState,
  showTooltipOverlayState,
  blackFridayState,
  selectedBlackFridayState,
  shippingTermsAndConditionsState
};

import gql from 'graphql-tag';

const GET_WALLET_BALANCE = gql`
  query {
    getWalletBalance {
      totalBalance
      bonusBalance
    }
  }
`;

const GET_TRANSACTION_REFERENCE = gql`
  query getReference($transaction: initTransactionInput!) {
    generateTransactionReference(referenceDetail: $transaction) {
      id
    }
  }
`;

const GET_USER = gql`
  query getUser($sub: String!) {
    user(userId: $sub) {
      topshipId
      id
      fullName
      phoneNumber
      email
    }
  }
`;

const GET_WALLET = gql`
  query {
    getWallet {
      id
      totalReceived
      totalSent
    }
  }
`;

const GET_TRANSACTIONS = gql`
  query getTransactions($filter: TransactionFilter) {
    getTransactions(filter: $filter) {
      totalPages
      totalCount
      currentPage
      list {
        transactionId
        amount
        transactionType
        description
      }
    }
  }
`;

const GET_SHIPMENTS = gql`
  query {
    getShipments {
      list {
        id
        pricingTier
        updatedDate
        trackingId
        itemCollectionMode
        shipmentStatus
        insuranceType
        isPaid
        totalCharge
        insuranceCharge
        senderDetail {
          addressLine1
          state
          country
        }
        receiverDetail {
          addressLine1
          state
          country
        }
      }
    }
  }
`;

const GET_SHIPMENT_DETAILS = gql`
  query getShipment($shipmentId: String!) {
    getShipment(id: $shipmentId) {
      id
      pricingTier
      updatedDate
      trackingId
      itemCollectionMode
      shipmentStatus
      insuranceType
      isPaid
      totalCharge
      insuranceCharge
      senderDetail {
        addressLine1
        addressLine2
        addressLine3
        state
        country
        name
        email
        phoneNumber
      }
      receiverDetail {
        addressLine1
        addressLine2
        addressLine3
        state
        country
        name
        email
        phoneNumber
      }
    }
  }
`;

const GET_SAVED_ADDRESSES = gql`
  query {
    getAddresses {
      list {
        id
        addressLine1
        addressLine2
        addressLine3
        city
        country
        state
        countryCode
      }
    }
  }
`;

const GET_SHIPMENT_RATES = gql`
  query getShipmentRates($shipmentDetail: GetShipmentRateInput!) {
    getShipmentRate(shipmentDetail: $shipmentDetail) {
      mode
      cost
      duration
      currency
    }
  }
`;

const TRACK_SHIPMENT = gql`
  query trackShipment($trackingId: String!) {
    trackShipment(trackingId: $trackingId) {
      id
      trackingId
      status
      message
      itemLocation
      shipment {
        id
        isPaid
        pricingTier
        insuranceType
        insuranceCharge
        pickupCharge
        shipmentCharge
        totalCharge
        estimatedDeliveryDate
        senderDetail {
          addressLine1
          state
          country
          name
          email
          phoneNumber
        }
        receiverDetail {
          addressLine1
          state
          country
          name
          email
          phoneNumber
        }
      }
    }
  }
`;

const GET_BANK_LIST = gql`
  query {
    getBankList {
      name
      code
    }
  }
`;
const GET_BANK_ACCOUNT_NAME = gql`
  query getBankAccountName($bankDetail: GetBankAccountNameInput!) {
    getBankAccountName(bankDetail: $bankDetail) {
      accountName
    }
  }
`;

const GET_BANK_ACCOUNTS = gql`
  query {
    getUserBankDetails {
      accountName
      accountNumber
      bankName
      createdDate
      id
    }
  }
`;

const GET_WITHDRAWAL_LOGS = gql`
  query getUserWithdrawalRequests($filter: WithdrawalRequestFilter) {
    getUserWithdrawalRequests(filter: $filter) {
      list {
        amount
        createdDate
        approvalStatus
        bankDetail {
          id
        }
      }
    }
  }
`;

const GET_COUNTRIES = gql`
  query {
    getCountries {
      code
      name
    }
  }
`;

const GET_STATES = gql`
  query getStates($countryCode: String!) {
    getStates(countryCode: $countryCode) {
      name
      countryCode
    }
  }
`;

const GET_CITIES = gql`
  query getCities($countryCode: String!) {
    getCities(countryCode: $countryCode) {
      cityName
      postcode
    }
  }
`;

export {
  GET_WALLET_BALANCE,
  GET_TRANSACTION_REFERENCE,
  GET_USER,
  GET_WALLET,
  GET_TRANSACTIONS,
  GET_SHIPMENTS,
  GET_SHIPMENT_DETAILS,
  GET_SAVED_ADDRESSES,
  GET_SHIPMENT_RATES,
  TRACK_SHIPMENT,
  GET_BANK_LIST,
  GET_BANK_ACCOUNT_NAME,
  GET_BANK_ACCOUNTS,
  GET_WITHDRAWAL_LOGS,
  GET_COUNTRIES,
  GET_STATES,
  GET_CITIES
};

import { Layout } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const { Content } = Layout;

interface DashboardShellProps {}

export const DashboardShell: React.FunctionComponent<DashboardShellProps> = (props) => {

	return (
		<Layout className="dashboard-shell">
			<Content
				className="md:pt-28"
				style={{ backgroundColor: "white", minHeight: "100vh" }}
			>
				{props.children}
			</Content>
		</Layout>
	);
};

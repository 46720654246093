import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import App from "./App";
import client from './services/client';

import "./static/styles/index.css";
import "./static/styles/index.scss";
import './index.scss';

ReactDOM.render(
	<React.StrictMode>
    <ApolloProvider client={client()}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>,
	document.getElementById("root")
);

import React, { useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Redirect, Link } from 'react-router-dom';
import userCircle from '../../assets/svg/UserCircle.svg';
import lockKey from '../../assets/svg/LockKey.svg';
import SubmitButton from '../../components/submit-button';
import { useLoginMutation } from '../../operations/mutations';
import { useShowAuthPage } from '../../utilities/show-auth-pages';
import PasswordInput from '../../components/password-input';

const Login = () => {
  const [login, { loading, error, data }] = useLoginMutation();
  const [renderPage] = useShowAuthPage();
  const [displayPassword, setDisplayPassword] = useState(false);

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email('Please enter a valid email address')
      .required('What is your email address?'),
    password: yup
      .string()
      .min(8, 'Please enter a password that is longer than 8 characters')
      .required('Please enter your password')
  });

  const { errors, touched, values, handleSubmit, handleChange } = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema,
    onSubmit: async values => {
      login(values);
    }
  });

  data && !error && <Redirect to='/chat' />;

  return renderPage(
    <>
      <div className='authentication__signin'>
        <div className='authentication__signin__form'>
          <form
            className='authentication__form__fields'
            onSubmit={handleSubmit}
          >
            <div className='authentication__signin__intro-text'>
              <h1 className='intro-text__heading'>Sign in to Topship</h1>
              <p className='intro-text__sub-heading'>
                Enter your details to sign in
              </p>
            </div>

            <div className='input-group--wrapper signin-form-controls'>
              <div
                className={`inputGroup ${
                  Boolean(errors.email && touched.email) && 'error'
                }`}
              >
                <label htmlFor='email' className='inputGroup__label'>
                  <img src={userCircle} alt='label-icon' />{' '}
                  <span>Email Address</span>
                </label>

                <input
                  type='text'
                  id='email'
                  name='email'
                  onChange={handleChange}
                  value={values.email}
                  disabled={loading}
                />

                <small>{touched.email && errors.email}</small>
              </div>

              <div
                className={`inputGroup mb-2 ${
                  Boolean(errors.password && touched.password) && 'error'
                }`}
              >
                <label htmlFor='password' className='inputGroup__label'>
                  <img src={lockKey} alt='label-icon' /> <span>Password</span>
                </label>

                <PasswordInput
                  type={displayPassword ? 'text' : 'password'}
                  id='password'
                  name='password'
                  onChange={handleChange}
                  value={values.password}
                  disabled={loading}
                />
                <small>{touched.password && errors.password}</small>
              </div>

            
              <div className='mt-4 authentication__button'>
                <SubmitButton
                  text='Sign In'
                  disabled={loading}
                  loading={loading}
                //   onClick={() =>
                //     ReactGA.event({
                //       category: 'Authentication',
                //       action: 'User pressed the log in button'
                //     })
                //   }
                />
              </div>
            </div>
           
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;

let backend_base_url;

switch (process.env.NODE_ENV) {
  case 'development':
    backend_base_url = 'localhost:3000/graphql';
}

export const environment = {
  backend_base_url: ''
};

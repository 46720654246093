import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import * as React from "react";

interface IAppProps {}

const antIcon = <LoadingOutlined spin />;

export function Loader(props: IAppProps) {
	return <Spin indicator={antIcon} />;
}

import React from 'react';
import { Redirect, Route,  } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useAuthToken } from '../token';
import { currentLoginState } from '../recoil/selectors';
import { verifyToken } from '../authorization';
import { useGetWalletQuery } from '../operations/queries';

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  // const history = browserHistory();
  const [authToken, setToken] = useAuthToken();
  const isLoggedIn = useRecoilValue(currentLoginState);
  const isValidToken = verifyToken(authToken);
  useGetWalletQuery();

  return (
    <Route
      {...rest}
      render={props => {
        if (isLoggedIn || (authToken && isValidToken.success)) {
          setToken('unauthorizedSession', '/');
          return <Component {...props} />;
        } else {
          const path = props.history.location.pathname;
          setToken('unauthorizedSession', path);
          return (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location }
              }}
            />
          );
        }
      }}
    />
  );
};
